import * as Api from "@/api/course/course";
import ChatList from "@/views/live/modules/chatroom/chatList/chatList.vue";
import TCPlayer from "@/components/TCPlayer/TCPlayer.vue";
import { mapActions, mapState } from "vuex";
import $ from "jquery";
import EXT_IM from "@/views/live/ext/im";
import * as Constant from "@/views/live/constants/constant";

export default {
  name: "courseReplay",
  data() {
    return {
      courseId: "",
      liveMode: "",
      videoSrc: "",
      isReplay: true,
      playerOptions: {},
      renderTime: "",
      msgShow: [],
      msgLave: [],
      showMoreMsgButton: false,
      videoStartBaseTime: "",
      timerArray: [],
      offsetArr: [],
      offsetNum: 0,
      fid: "",
    };
  },
  mounted() {
    this.clearMsgList();
    this.liveMode = this.$route.path.includes("private")
      ? Constant.LIVE_MODE.private
      : Constant.LIVE_MODE.class;
    this.courseId = this.$route.params.id;
    this.getReplayDetail();
  },
  computed: {
    ...mapState({
      userId: (state) => state.live.userId,
      msgs: (state) => state.live.msgs,
    }),
    routeMode() {
      return this.$route.path.includes("spoken");
    },
  },
  methods: {
    getReplayDetail() {
      Api.replayCourseDetail(
        "",
        this.courseId,
        this.liveMode,
        this.routeMode
          ? Constant.ENTERANCE.speaking
          : Constant.ENTERANCE.course,
        (res) => {
          this.fid = res.video_id;
          // this.fid = "3270835009053652412"; //测试用
          this.videoStartBaseTime = res.video_started_at[0].time;
          this.timerArray = res.video_started_at;
          let self = this;
          this.calcOffset();
          $.ajax({
            url: `${res.im_record_url}`,
            // url: 'https://file.papaen.com/test/2023/05/16/json/4QPvtL0VuuFewEDiHMWYu5R41CzeyJlXJ3b4lDCs.live/2697735538', //测试用
            type: "get",
            dataType: "jsonp",
            jsonp: "jsonp_callback",
            jsonpCallback: "ImMessage",
            success: function(res) {
              self.msgLave = res.reverse();
            },
          });
        }
      );
    },
    playVideo() {
      this.$refs.videoPlayer.play();
    },

    renderMsg(currentTime) {
      this.msgLave.forEach((ele, index) => {
        if (ele.timestamp <= currentTime) {
          if (!ele.isShow) {
            this.handleMsgShowInBox(ele);
          }
          ele.isShow = true;
        }
      });
    },
    handleMsgShowInBox(msg) {
      let accountObj = msg.from_account;
      let account = "";
      if (accountObj.identity === "teacher") {
        account = "personal";
      } else {
        account = accountObj.nickname || accountObj.account;
      }
      let msgContent = msg.body;

      if (msg.body.msg_type === "TIMTextElem") {
        EXT_IM.handleGroupTextMsg(msgContent.msg_content.text).then((text) => {
          this.addMsg({
            send: account,
            content: text,
            type: "chat",
            avatar: accountObj.avatar,
            identity: accountObj.identity,
          });
          this.$nextTick(() => {
            var msgbox = document.querySelector("#msg_box");
            msgbox.scrollTop = msgbox.scrollHeight;
          });
        });
      } else {
        let img = `<img class="chat_img" draggable="false"  bigimgsrc="${msgContent.msg_content.images[0].url}"  style="max-width: 150px" src="${msgContent.msg_content.images[0].url}">`;

        this.addMsg({
          send: account,
          content: img,
          type: "chat",
          avatar: accountObj.avatar,
          identity: accountObj.identity,
        });
        this.$nextTick(() => {
          var msgbox = document.querySelector("#msg_box");
          msgbox.scrollTop = msgbox.scrollHeight;
        });
      }
    },

    calcOffset() {
      let arr = [];
      let offset = 0;
      this.timerArray.forEach((ele) => {
        offset = offset + parseInt(ele.duration);
        arr.push(offset);
      });
      this.offsetArr = arr;
    },
    onPlayerTimeupdate(player) {
      let baseTimeTamp = parseInt(this.videoStartBaseTime);
      let currentTime = Math.floor(player.currentTime());
      let offsetArr = this.offsetArr;
      for (let i = 0; i < offsetArr.length; ++i) {
        if (currentTime >= offsetArr[i] && currentTime < offsetArr[i + 1]) {
          this.videoStartBaseTime = parseInt(this.timerArray[i + 1].time);
          this.offsetNum = offsetArr[i];
        }
      }
      let calcTime = baseTimeTamp + currentTime - this.offsetNum;
      this.renderMsg(calcTime);
    },
    onPlayerEnded(player) {
      this.showMoreMsgButton = true;
    },
    showLaveMsg() {
      let laveMsg = [];
      this.msgLave.forEach((ele, index) => {
        if (!ele.isShow) {
          this.handleMsgShowInBox(ele);
          ele.isShow = true;
        }
      });
      this.showMoreMsgButton = false;
    },
    ...mapActions(["addMsg", "setLiveMode", "clearMsgList"]),
  },
  watch: {},
  components: {
    ChatList,
    TCPlayer,
  },
};
