import * as Config from '@/config/live/live'

export default {
  data () {
    return {
      selectorId: '',
      player: null
    }
  },
  props: {
    fid: {
      type: String,
      default: ''
    }
  },
  mounted () {
  },
  methods: {
    initPlay (fid) {
      
      console.log()
      this.player = TCPlayer('player-container-id', { // player-container-id 为播放器容器 ID,必须与 html 中一致
        fileID: fid, // 请传入需要播放的视频 filID（必须）
        appID: Number(JSON.parse(localStorage.getItem('SUBAppid'))) , // 请传入点播账号的 appID（必须）
        // appID: 1500006707, // 请传入点播账号的 appID（必须）
        playbackRates: [0.75, 1, 1.25, 1.5, 1.75, 2],
        controlBar: {
          fullscreenToggle: false,
          QualitySwitcherMenuButton: false
        },
        plugins: {
          ContinuePlay: { // 开启续播功能
            auto: true, //[可选] 是否在视频播放后自动续播
            text: '上次播放至 ', //[可选] 提示文案
            btnText: '恢复播放' //[可选] 按钮文案
          },
        }

      })
      this.player.on('timeupdate', () => {
        this.$emit('onPlayerTimeupdate', this.player)
      })
    },
    changeFid (fid) {
      this.player.loadVideoByID({
        fileID: fid,
        appID: Config.TCPlayerAppId,
      })
    }
  },
  watch: {
    fid (newVal) {
      if (this.player) {
        this.changeFid(this.fid)
      } else {
        this.initPlay(this.fid)
      }
    }
  }
}
