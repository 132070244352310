<template>
  <div class="tcplayer">
    <video id='player-container-id' class="player-size" preload="auto" playsinline webkit-playsinline>-->
    </video>
  </div>
</template>

<script>
  import TCPlayerJs from '@/components/TCPlayer/TCPlayer.js'

  export default TCPlayerJs
</script>

<style lang="less" scoped>
  .tcplayer {
    width: 100%;
    height: 100%;

    .player-size {
      width: 100%;
      height: 100%;
    }
    /deep/ .draw-fill {
      display: none;
    }
  }
</style>
