<template>
  <div class="courseReplay">
    <div class="course_video">
      <t-c-player class="player-size" @onPlayerTimeupdate="onPlayerTimeupdate" @onPlayerEnded="onPlayerEnded"
                  :fid="fid"></t-c-player>
    </div>
    <div class="course_chat">
      <div class="top">聊天</div>
      <div class="chat_box">
        <chat-list :isReplay="isReplay"></chat-list>
      </div>
      <div class="show_lave" v-show="showMoreMsgButton">
        <div class="show_lave_msg" @click="showLaveMsg">展开剩余全部聊天 <i class="el-icon-arrow-down"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
  import CourseReplayJs from './courseReplay.js'

  export default CourseReplayJs
</script>

<style lang="less" scoped>
  .courseReplay {
    width: 100%;
    height: 100vh;
    display: flex;

    .course_video {
      width: 80%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;

      .player-size {
        width: 100%;
        height: 100%;
      }

      .video_mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 100vh;
        background: rgba(0, 0, 0, 0.3);
        z-index: 99;

        i {
          font-size: 40px;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    .course_chat {
      width: 20%;
      min-width: 320px;
      height: 100%;
      position: relative;

      .top {
        width: 100%;
        height: 50px;
        text-align: center;
        line-height: 50px;
        border-bottom: 1px solid #29d087;
      }

      .chat_box {
        width: 100%;
        height: calc(100% - 50px);
      }

      .show_lave {
        cursor: pointer;
        width: 100%;
        position: absolute;
        bottom: 20px;
        display: flex;
        justify-content: center;
      }

      .show_lave_msg {

        width: 180px;
        height: 30px;
        border-radius: 20px;
        background: #EBEBEB;
        text-align: center;
        line-height: 30px;
        font-size: 12px;
        color: #B3B3B3;
      }
    }
  }
</style>
